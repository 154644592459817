import React from 'react'
import { Element } from 'react-scroll'

import PageWrapper from '../components/PageWrapper'
import Hero from '../sections/landing1/RentalHero'
import Info from '../sections/landing1/RentalInfo'
import { graphql } from "gatsby";
import SEO from '../components/seo'

const RentalPage = ({data}) => {

  const listing = data.listings
  const inspection_time = data.allIreinspection
  var listingAgent = listing.listingAgent

  if (listingAgent == null){
    listingAgent = {
      "firstName": "Lina",
      "lastName": "Davies",
      "mobile": "0402 268 096",
    }
  }

  return (
    <>
      <PageWrapper>
        <SEO title={`Rental: ${listing.streetNo} ${listing.street}, ${listing.suburb} ${listing.postcode}`} description={`$${listing.price} p/w | ${listing.bedrooms} Bedrooms | ${listing.bathrooms} Bathrooms | ${`${listing.carports + listing.garages}`} Car Spots | Available on: ${listing.availableDate} via East Avenue Realty`} image={listing.mainImageUrl}/>

        <Hero image_data={listing.images}/>
        <Info
          type="Apartment"
          id__normalized={listing.id__normalized}
          insepction_times={inspection_time}
          address={`${listing.streetNo} ${listing.street}, ${listing.suburb} ${listing.postcode}`}
          price={listing.price}
          bedroom={listing.bedrooms}
          bathroom={listing.bathrooms}
          car={`${listing.carports + listing.garages}`}
          available={listing.availableDate}
          bond={listing.bond}
          copy={listing.description}
          iFrameBookLink={listing.iFrameBookLink}
          leasingAgent={`${listingAgent.firstName} ${listingAgent.lastName}`}
          leasingAgentMobile={listingAgent.mobile}

        />
      </PageWrapper>
    </>
  )
}
export default RentalPage

export const query = graphql`
    query($id: String) {
      listings(id: {eq: $id}) {
        id__normalized
        price
        bathrooms
        bedrooms
        carports
        streetNo
        street
        suburb
        postcode
        mainImageUrl
        garages
        images
      	description
        availableDate
        iFrameBookLink
        bond
        listingAgent {
          firstName
          lastName
          mobile
        }
      }
      allIreinspection {
        nodes {
          DataRecords {
            StartTimeLocal
            StartDayLocal
            Property {
              ID
            }
            Private
          }
        }
      }
    
  }
  
`;
