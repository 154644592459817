import React from 'react'
import styled from 'styled-components'

import { Section, Text } from '../../components/Core'
import earBg from '../../assets/ear/ear_bg.png'
import lina from '../../assets/ear/person/lina.jpg'

import { Container, Col, Row } from 'react-bootstrap'

import { device } from '../../utils'

import { Link } from "gatsby"

import bbfy from "bbfy"

var unescapeJs = require('unescape-js');


const StyledSection = styled(Section)`
  padding-top: 0px;
  background-color: white;
  background-position: center;
  background-size: cover;

  .share-box {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .type {
    text-transform: uppercase;
    font-size: 30px;
    line-height: 35px;
    font-weight: 200;
  }

  .mobile {
    display: none;
  }

  .address {
    padding-top: 10px;
    text-transform: uppercase;
    font-size: 40px;
    line-height: 50px;
    font-weight: 200;
  }

  .apply {
    display: flex;
    flex-direction: column;
  }
  .subline {
    padding-top: 10px;
    text-transform: uppercase;
    font-size: 20px;
    line-height: 25px;
    font-weight: 200;
  }

  .copy {
    font-size: 20px;
    line-height: 25px;
    font-weight: 200;
    padding-bottom: 10px;
    padding-right: 15px;
  }

  .share {
    font-size: 25px;
    line-height: 30px;
    font-weight: bold;
    padding-bottom: 10px;
    padding-right: 15px;
  }

  .copyrow {
    padding-top: 15px;
  }

  .agent {
    border-top: 1px solid #707070;

    padding-top: 15px;
    font-size: 25px;
    line-height: 30px;
  }
  .agent-box {
    position: relative;
    display: flex;
    flex-align: row;
  }

  .agent-details {
    font-size: 20px;
    line-height: 25px;
    font-weight: 200;
    padding-bottom: 15px;
  }

  .agent-box div {
    padding-top: 15px;
  }

  .cta {
    padding: 10px 30px 10px 30px;
    font-size: 20px;
    line-height: 25px;
    border: 1px solid #707070;
    display: inline-block;
    text-transform: uppercase;
  }



  .side-box {
    border-bottom: 1px solid #707070;
    padding-bottom: 10px;
    margin-bottom: 10px;
  }

  .side-box-title {
    text-transform: uppercase;
    font-size: 25px;
    line-height: 30px;
  }

  .side-box-apply {
    font-weight: 200;
    font-size: 25px;
    line-height: 35px;
    color: #899caa;
  }

  .side-box-copy {
    padding-top: 5px;
    font-weight: 200;
    font-size: 20px;
    line-height: 25px;
  }

  .social-sharing svg {
    height: 25px;
    margin-right: 10px;
  }

  .social-sharing {
    padding-bottom: 15px;
  }

  @media ${device.sm} {
    margin: 1rem;

    .mobile {
      display: block;
    }

    .desktop {
      display: none;
    }

    .address {
      font-size: 25px;
      line-height: 30px;
    }

    .subline {
      padding-top: 0px;
      font-size: 20px;
      line-height: 25px;
    }

    .subline:nth-child(2) {
      padding-top: 15px;
    }

    .copy {
      font-size: 15px;
      line-height: 20px;
      padding-bottom: 20px;
      padding-right: 0px;
    }

    .share {
      font-size: 20px;
      line-height: 25px;
    }

    .social-sharing svg {
      height: 20px;
      margin-right: 10px;
    }

    .agent {
      border-top: none;
    }

    .side-box-title {
      font-size: 15px;
      line-height: 25px;
    }

    .side-box-apply {
      font-size: 15px;
      line-height: 25px;
    }

    .side-box-copy {
      font-size: 15px;
      line-height: 25px;
    }

    .apply {
      border-bottom: 1px solid #707070 !important;
    }

    .tablet {
      display: none;
    }
  }

  @media ${device.md} {

    .agent-img{
      padding-right: 20px;
      max-width: 270px;
    }
    .mobile {
      display: block;
    }

    .desktop {
      display: none;
    }

    .tablet-fix {
      display: none;
    }

    .tablet {
      display: block;
    }

    .sharebox-other {
      display: none;
    }

    .copy {
      padding-bottom: 2rem;
    }
  }

  @media ${device.lg} {
    .mobile {
      display: none;
    }

    .desktop {
      display: flex;
    }

    .tablet {
      display: none;
    }
  }

  .nb {
    border-bottom: none;
  }

  .enquire-now {
    border: 1px solid black;
    display: inline-block;
    padding: 5px 10px 5px 10px;
    margin-top: 10px;
  }
`

const Info = ({
  type,
  address,
  price,
  bedroom,
  bathroom,
  available,
  bond,
  term,
  copy,
  car,
  iFrameBookLink,
  leasingAgent,
  leasingAgentMobile,
  insepction_times,
  id__normalized

}) => {

  const convert = bbfy.converter();

  const html_copy = convert(unescapeJs(copy));


  var times = []

  var start_date = false
  var start_time = false

  var options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };

  try {
    for (var item in insepction_times.nodes[0].DataRecords) {
      var listing = insepction_times.nodes[0].DataRecords[item];
      
      var dateOffset = (24*60*60*1000) * 0; //1 days

      if (listing.Property.ID == id__normalized && (new Date(listing.StartDayLocal) > new Date()) && listing.Private == false) {
        times.push([listing.StartDayLocal, listing.StartTimeLocal]);
      }

    }

    if (times.length > 0) {
      var max_date = times.slice(-1)[0];
      var internal_date = new Date(max_date[0]);
      start_date = internal_date.toLocaleDateString('en-au', options);
      var internal_time = new Date(`2021-01-01 ${max_date[1]}`);
      start_time = internal_time.toLocaleTimeString('en-au', { hour: '2-digit', minute: '2-digit' })
    }
  } catch {

  }

  try{
    if (new Date(available) < new Date()){
      available = "Now"
    }
  }catch{

  }



  return (
    <>
      {/* <!-- Hero Area --> */}
      <StyledSection className="position-relative">
        <Container>
          <Row>
            <Text className="address" color="#707070">
              {address}
            </Text>
            <Text className="subline" color="#707070">
              ${price} Per Week
            </Text>

            <Text className="subline" color="#707070">
              {bedroom} Bedroom | {bathroom} Bathroom | {car} Car
            </Text>
          </Row>
          <Row className="copyrow">
            <Col lg={8}>
              <Text className="copy" dangerouslySetInnerHTML={{ __html: html_copy }}></Text>
              <div className="share-box sharebox-other" style={{ display: "none" }}>
                <Text className="share">SHARE</Text>
                <div className="social-sharing">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25.362"
                    height="25.356"
                    viewBox="0 0 25.362 25.356"
                  >
                    <path
                      id="Icon_awesome-instagram"
                      data-name="Icon awesome-instagram"
                      d="M12.678,8.415a6.5,6.5,0,1,0,6.5,6.5A6.491,6.491,0,0,0,12.678,8.415Zm0,10.727A4.226,4.226,0,1,1,16.9,14.916a4.234,4.234,0,0,1-4.226,4.226ZM20.962,8.149a1.516,1.516,0,1,1-1.516-1.516A1.513,1.513,0,0,1,20.962,8.149Zm4.306,1.539a7.5,7.5,0,0,0-2.048-5.313,7.553,7.553,0,0,0-5.313-2.048c-2.093-.119-8.368-.119-10.462,0A7.542,7.542,0,0,0,2.132,4.369,7.529,7.529,0,0,0,.084,9.682c-.119,2.093-.119,8.368,0,10.462a7.5,7.5,0,0,0,2.048,5.313A7.563,7.563,0,0,0,7.445,27.5c2.093.119,8.368.119,10.462,0a7.5,7.5,0,0,0,5.313-2.048,7.553,7.553,0,0,0,2.048-5.313c.119-2.093.119-8.362,0-10.456Zm-2.7,12.7a4.279,4.279,0,0,1-2.41,2.41c-1.669.662-5.63.509-7.474.509S6.868,25.457,5.2,24.8a4.279,4.279,0,0,1-2.41-2.41c-.662-1.669-.509-5.63-.509-7.474s-.147-5.811.509-7.474A4.279,4.279,0,0,1,5.2,5.031c1.669-.662,5.63-.509,7.474-.509s5.811-.147,7.474.509a4.279,4.279,0,0,1,2.41,2.41c.662,1.669.509,5.63.509,7.474S23.225,20.726,22.563,22.39Z"
                      transform="translate(0.005 -2.238)"
                      fill="#707070"
                    />
                  </svg>
                  <a href="https://www.facebook.com/sharer.php?u=">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="15.515"
                      height="28.969"
                      viewBox="0 0 15.515 28.969"
                    >
                      <path
                        id="Icon_awesome-facebook-f"
                        data-name="Icon awesome-facebook-f"
                        d="M16.108,16.295l.8-5.243h-5.03V7.65a2.621,2.621,0,0,1,2.956-2.832h2.287V.354A27.889,27.889,0,0,0,13.065,0C8.922,0,6.214,2.511,6.214,7.057v4H1.609v5.243H6.214V28.969h5.668V16.295Z"
                        transform="translate(-1.609)"
                        fill="#707070"
                      />
                    </svg>
                  </a>

                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25.348"
                    height="25.347"
                    viewBox="0 0 25.348 25.347"
                  >
                    <path
                      id="Icon_awesome-linkedin-in"
                      data-name="Icon awesome-linkedin-in"
                      d="M5.674,25.348H.419V8.425H5.674ZM3.043,6.116A3.058,3.058,0,1,1,6.087,3.044,3.069,3.069,0,0,1,3.043,6.116Zm22.3,19.231H20.1V17.11c0-1.963-.04-4.481-2.732-4.481-2.732,0-3.151,2.133-3.151,4.34v8.379H8.966V8.425h5.04v2.308h.074A5.522,5.522,0,0,1,19.051,8c5.318,0,6.3,3.5,6.3,8.051v9.3Z"
                      transform="translate(0 -0.001)"
                      fill="#707070"
                    />
                  </svg>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="28.319"
                    height="22.313"
                    viewBox="0 0 28.319 22.313"
                  >
                    <g
                      id="Icon_feather-mail"
                      data-name="Icon feather-mail"
                      transform="translate(2.089 1.5)"
                    >
                      <path
                        id="Path_38"
                        data-name="Path 38"
                        d="M5.414,6H24.727a2.421,2.421,0,0,1,2.414,2.414V22.9a2.421,2.421,0,0,1-2.414,2.414H5.414A2.421,2.421,0,0,1,3,22.9V8.414A2.421,2.421,0,0,1,5.414,6Z"
                        transform="translate(-3 -6)"
                        fill="none"
                        stroke="#707070"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="3"
                      />
                      <path
                        id="Path_39"
                        data-name="Path 39"
                        d="M27.141,9,15.07,17.449,3,9"
                        transform="translate(-3 -6.586)"
                        fill="none"
                        stroke="#707070"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="3"
                      />
                    </g>
                  </svg>
                </div>
              </div>
              <Text className="agent desktop">LEASING AGENT</Text>
              <div className="agent-box desktop">
                {leasingAgent == "Lina Davies" &&
                  <div>
                    <img src={lina} className="w-100 agent-img" />
                  </div>
                }
                <div className="detail-block">
                  <Text className="agent-details">
                    {leasingAgent}
                    <br />
                    East Avenue Realty
                    <br />
                    <a href={`tel:${leasingAgentMobile}`}>{leasingAgentMobile}</a>
                    <br />
                  </Text>
                  <Link to='/contact' className='hover d-inline-block'>
                    <Text className="cta d-inline-block">Enquire Now</Text>

                  </Link>
                </div>
              </div>
            </Col>
            <Col sm={12} md={6} lg={4}>
            
              <div className="side-box">
                <Text className="side-box-title">Available</Text>
                <Text className="side-box-copy">{available}</Text>
              </div>
              {start_date != false &&
                <div className="side-box">
                  <Text className="side-box-title">Next Inspection</Text>
                  <Text className="side-box-copy">{start_date} | {start_time}</Text>
                </div>
              }
              <div className="side-box">
                <Text className="side-box-title">Bond</Text>
                <Text className="side-box-copy">${bond}</Text>
              </div>
              <div className="side-box nb apply">
                <a href="https://t-app.com.au/ear" target="_blank" rel="nofollow" className='hover d-inline-block'>
                  <Text className="side-box-apply">Apply Online</Text>

                </a>
                <a href={iFrameBookLink} target="_blank" className='hover d-inline-block'>
                  <Text className="side-box-apply">Book An Inspection</Text>
                </a>

              </div>

              <div className="side-box nb mobile tablet-fix">
                <Text className="side-box-title">Leasing Agent</Text>
                <Text className="side-box-copy">{leasingAgent}</Text>
                <Text className="side-box-copy">
                  East Avenue Realty
                </Text>
                <Text className="side-box-copy"><a href={`tel:${leasingAgentMobile}`}>{leasingAgentMobile}</a></Text>
                <Link to='/contact' className='hover d-inline-block'>

                  <Text className="side-box-copy enquire-now">ENQUIRE NOW</Text>

                </Link>
              </div>
            </Col>

            <Col md={6}>
              <div className="side-box nb mobile tablet">
                <Text className="side-box-title">Leasing Agent</Text>
                <Text className="side-box-copy">{leasingAgent}</Text>
                <Text className="side-box-copy">
                  East Avenue Realty
                </Text>
                <Text className="side-box-copy"><a href={`tel:${leasingAgentMobile}`}>{leasingAgentMobile}</a></Text>
                <Text className="side-box-copy enquire-now">ENQUIRE NOW</Text>
                <div className="share-box" style={{ display: "none" }}>
                  <Text className="share pt-3">SHARE</Text>
                  <div className="social-sharing">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="25.362"
                      height="25.356"
                      viewBox="0 0 25.362 25.356"
                    >
                      <path
                        id="Icon_awesome-instagram"
                        data-name="Icon awesome-instagram"
                        d="M12.678,8.415a6.5,6.5,0,1,0,6.5,6.5A6.491,6.491,0,0,0,12.678,8.415Zm0,10.727A4.226,4.226,0,1,1,16.9,14.916a4.234,4.234,0,0,1-4.226,4.226ZM20.962,8.149a1.516,1.516,0,1,1-1.516-1.516A1.513,1.513,0,0,1,20.962,8.149Zm4.306,1.539a7.5,7.5,0,0,0-2.048-5.313,7.553,7.553,0,0,0-5.313-2.048c-2.093-.119-8.368-.119-10.462,0A7.542,7.542,0,0,0,2.132,4.369,7.529,7.529,0,0,0,.084,9.682c-.119,2.093-.119,8.368,0,10.462a7.5,7.5,0,0,0,2.048,5.313A7.563,7.563,0,0,0,7.445,27.5c2.093.119,8.368.119,10.462,0a7.5,7.5,0,0,0,5.313-2.048,7.553,7.553,0,0,0,2.048-5.313c.119-2.093.119-8.362,0-10.456Zm-2.7,12.7a4.279,4.279,0,0,1-2.41,2.41c-1.669.662-5.63.509-7.474.509S6.868,25.457,5.2,24.8a4.279,4.279,0,0,1-2.41-2.41c-.662-1.669-.509-5.63-.509-7.474s-.147-5.811.509-7.474A4.279,4.279,0,0,1,5.2,5.031c1.669-.662,5.63-.509,7.474-.509s5.811-.147,7.474.509a4.279,4.279,0,0,1,2.41,2.41c.662,1.669.509,5.63.509,7.474S23.225,20.726,22.563,22.39Z"
                        transform="translate(0.005 -2.238)"
                        fill="#707070"
                      />
                    </svg>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="15.515"
                      height="28.969"
                      viewBox="0 0 15.515 28.969"
                    >
                      <path
                        id="Icon_awesome-facebook-f"
                        data-name="Icon awesome-facebook-f"
                        d="M16.108,16.295l.8-5.243h-5.03V7.65a2.621,2.621,0,0,1,2.956-2.832h2.287V.354A27.889,27.889,0,0,0,13.065,0C8.922,0,6.214,2.511,6.214,7.057v4H1.609v5.243H6.214V28.969h5.668V16.295Z"
                        transform="translate(-1.609)"
                        fill="#707070"
                      />
                    </svg>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="25.348"
                      height="25.347"
                      viewBox="0 0 25.348 25.347"
                    >
                      <path
                        id="Icon_awesome-linkedin-in"
                        data-name="Icon awesome-linkedin-in"
                        d="M5.674,25.348H.419V8.425H5.674ZM3.043,6.116A3.058,3.058,0,1,1,6.087,3.044,3.069,3.069,0,0,1,3.043,6.116Zm22.3,19.231H20.1V17.11c0-1.963-.04-4.481-2.732-4.481-2.732,0-3.151,2.133-3.151,4.34v8.379H8.966V8.425h5.04v2.308h.074A5.522,5.522,0,0,1,19.051,8c5.318,0,6.3,3.5,6.3,8.051v9.3Z"
                        transform="translate(0 -0.001)"
                        fill="#707070"
                      />
                    </svg>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="28.319"
                      height="22.313"
                      viewBox="0 0 28.319 22.313"
                    >
                      <g
                        id="Icon_feather-mail"
                        data-name="Icon feather-mail"
                        transform="translate(2.089 1.5)"
                      >
                        <path
                          id="Path_38"
                          data-name="Path 38"
                          d="M5.414,6H24.727a2.421,2.421,0,0,1,2.414,2.414V22.9a2.421,2.421,0,0,1-2.414,2.414H5.414A2.421,2.421,0,0,1,3,22.9V8.414A2.421,2.421,0,0,1,5.414,6Z"
                          transform="translate(-3 -6)"
                          fill="none"
                          stroke="#707070"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="3"
                        />
                        <path
                          id="Path_39"
                          data-name="Path 39"
                          d="M27.141,9,15.07,17.449,3,9"
                          transform="translate(-3 -6.586)"
                          fill="none"
                          stroke="#707070"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="3"
                        />
                      </g>
                    </svg>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </StyledSection>
    </>
  )
}

export default Info
