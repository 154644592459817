import React, { useState } from 'react'
import styled from 'styled-components'

import { Section, Text } from '../../components/Core'

import houseOne from '../../assets/ear/house_1.png'
import houseTwo from '../../assets/ear/house_2.png'

import { Container } from 'react-bootstrap'

import 'react-responsive-carousel/lib/styles/carousel.min.css' // requires a loader

import 'react-image-gallery/styles/css/image-gallery.css' // requires a loader

import { Carousel } from 'react-responsive-carousel'

import waterBg from '../../assets/ear/water_bg.png'

import ImageGallery from 'react-image-gallery';



import { device } from '../../utils'

const StyledSection = styled(Section)`
  background-color: white;
  background-position: center;
  background-size: cover;
  padding-bottom: 0px;
  padding-top: 50px;

  .image-gallery-right-nav{
    display: none;
  }

  .image-gallery-left-nav{
    display: none;
  }

  .image-gallery-thumbnail.active{
    border: 1px solid black;
  }
  .image-gallery-thumbnail:hover{
    border: none;
  }
  .image-gallery-thumbnail:focus{
    border: inherit;
  }







  .image-gallery-slide .image-gallery-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

  .image-gallery-slide {
    max-height: 800px;
    height: 100%;
  }

  .image-gallery-image{
    height: 775px !important;
  }

  .image-gallery-icon{
    filter: none;
    color: white;
  }


  .image-gallery-thumbnail{
    width: 200px;
  }
  .image-gallery-thumbnail-image {

    object-fit: cover;
  }

  .carousel-status {
    display: none;
  }

  .swipe {
    display: none;

    margin-left: 1rem;
    font-size: 15px;
    padding-right: var(--bs-gutter-x, 0.75rem);
    padding-left: var(--bs-gutter-x, 0.75rem);
  }

  @media ${device.sm} {

    .image-gallery-icon{
      display: none;
    }
    padding-top: 5px;

    .image-gallery-thumbnails {
      display: none !important;
    }

    .slide img {
      height: 275px !important;
    }

      
    .image-gallery-image{
      height: 275px !important;
    }

    .swipe {
      display: block;
    }

    .hero-container {
      padding-left: 0px;
      padding-right: 0px;
    }

    .control-arrow {
      display: none;
    }
  }

  @media ${device.md} {
    padding-top: 35px;

    .image-gallery-thumbnails {
      display: none !important;
    }

  
    .image-gallery-image{
      height: 450px !important;
    }



    .hero-container {
      padding-left: 0px;
      padding-right: 0px;
    }

    .swipe {
      margin-left: 0px;
      padding-right: 0px;

      display: block;
    }

    .carousel-root {
      margin-left: -50px;
      margin-right: -50px;
    }
  }

  @media ${device.lg} {
    .image-gallery-thumbnails {
      display: block !important;
    }

 


    .image-gallery-image{
      height: 775px !important;
    }

    .carousel-root {
      margin-left: 0px;
      margin-right: 0px;
    }

    .swipe {
      display: none;
    }
  }
`

const Hero = ({image_data}) => {
  const [count, setCount] = useState(1)

  var images = []

  {image_data.map((item, index) => {
    const image = {
      original: item,
      thumbnail: item,
      thumbnailWidth: "200px",
      thumbnailHeight: "150px",
    }
    images.push(image)
  })}




  return (
    <>
      {/* <!-- Hero Area --> */}
      <StyledSection hero className="position-relative">
        <Container className="hero-container">
          <ImageGallery showPlayButton={false} items={images} onBeforeSlide={(currentSlide) => {setCount(currentSlide + 1)}}/>
          <Text className="swipe">Image {count}/{image_data.length} | Swipe for next</Text>
        </Container>
      </StyledSection>
    </>
  )
}

export default Hero
